import PHP from "./php.js";
import Button from "./button.js";
import { useRef } from "react";
import * as htmlToImage from "html-to-image";

const Report = (data) => {
   var order = data.data;
  const count = data.data.reduce((previousValue, currentValue) => {
    let i = (previousValue += 1);
    return i;
  }, 0);
  const countsum = data.data.reduce((previousValue, currentValue) => {
    return previousValue + currentValue.totalsum;
  }, 0);
  const countbuy = data.data.reduce((previousValue, currentValue) => {
    return previousValue + currentValue.count;
  }, 0);
  // const countprofit = data.data.reduce((previousValue, currentValue) => {
  //     return previousValue + currentValue.profit;
  // },0);
  const countreal = data.data.reduce((previousValue, currentValue) => {
    let i =
      currentValue.status_f === "on" ? (previousValue += 1) : previousValue;
    return i;
  }, 0);

  const domEl = useRef(null);

  const downloadImage = async () => {
    domEl.current.className = "w-max p-3";
    const dataUrl = await htmlToImage.toPng(domEl.current);
    const link = document.createElement("a");
    link.download = "html-to-img.png";
    link.href = dataUrl;
    link.click();
    domEl.current.className = "w-max p-3 hidden";
  };
  return (
    <>
      <div className="grid grid-cols-3 mt-5">
        <div className="span-col-1 text-xl text-slate-200">
          <div className="m-3 p-3 border-sky-500 border-2 bg-secondary h-15 text-center rounded-xl">
            สั่งซื้อ
            <br />
            {count} คน
          </div>
        </div>
        <div className="span-col-1 text-xl text-slate-200">
          <div className="m-3 p-3 border-sky-500 border-2 bg-secondary h-15 text-center rounded-xl">
            โอนแล้ว
            <br />
            {countreal} คน
          </div>
        </div>
        <div className="span-col-1 text-xl text-slate-200">
          <div className="m-3 p-3 border-sky-500 border-2 bg-secondary h-15 text-center rounded-xl">
            ยอดโอน
            <br />
            {countsum.toLocaleString()}
          </div>
        </div>
        <div className="span-col-1 text-xl text-slate-200">
          <div className="m-3 p-3 border-sky-500 border-2 bg-secondary h-15 text-center rounded-xl">
            สั่งซื้อ
            <br />
            {countbuy} ชิ้น
          </div>
        </div>
        {/* <div className="span-col-1 text-xl text-slate-200">
                    <div className="m-3 p-3 border-sky-500 border-2 bg-secondary h-15 text-center rounded-xl">
                        กำไร<br />
                        {countprofit.toLocaleString()} บาท
                    </div>
                </div> */}
        <div className="span-col-1 text-xl text-slate-200 cursor-pointer" onClick={downloadImage}>
          <div className="m-3 p-3 border-sky-500 border-2 bg-secondary h-15 text-center rounded-xl hover:bg-slate-900">
            Export
            <br />
            Summary
          </div>
        </div>
        <div className="w-max p-3 hidden" ref={domEl}>
          <table className="text-sm text-left bg-white p-3">
            <thead>
              <tr>
                <th className="py-4 px-6">ข้อมูลลูกค้า</th>
                <th>รหัสสินค้า</th>
                <th>ยอดโอน</th>
                <th>สรุปยอด</th>
                <th>โอนแล้ว</th>
              </tr>
            </thead>
            <tbody>
              {order.map((val, key) => {
                const tags =
                  val.product !== 's:0:"";' ? PHP.parse(val.product) : [];
                return (
                  <tr key={key} className="bg-white border-b">
                    <td className="py-4 px-6">
                      ชื่อ : {val.name}
                      <br />
                      Tiktok : {val.tiktok}
                      <br />
                      Line : {val.line}
                    </td>
                    <td>
                      {tags.map((tag, index) => {
                        return (
                          <span className="p-2" key={index}>
                            {tag}
                          </span>
                        );
                      })}
                    </td>
                    <td>{val.total}</td>
                    <td>
                      <Button name="สรุปยอด" status={val.status} />
                    </td>
                    <td className="pr-6">
                      <Button name="โอนแล้ว" status={val.status_f} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Report;
