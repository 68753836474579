import Axios from "axios";
import { useEffect, useState } from "react";
import "./App.css";
import PHP from "./php.js";
import Button from "./button.js";
import TagIndex from "./tagindex.js";
import { SpinnerCircularFixed } from "spinners-react";
import React from "react";
import { FaTiktok } from "react-icons/fa";
import { SiLine } from "react-icons/si";
import { BiUser } from "react-icons/bi";
import { MdContentCopy, MdEdit } from "react-icons/md";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Link } from "react-router-dom";
import Report from "./report";

Notify.init({
  fontFamily: "Mitr",
});

const Home = () => {
  const [show, setShow] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [copyList, setCopyList] = useState("");
  const [date, setDate] = useState("");
  const [ship, setShip] = useState(0);
  const [additional, setAdditional] = useState("");
  const getOrder = async () => {
    setLoading(true);

    const order = await Axios.get(`${process.env.REACT_APP_API_URL}/order`);
    // const category = await Axios.get(
    //   `${process.env.REACT_APP_API_URL}/category`
    // );
    const date = await Axios.get(`${process.env.REACT_APP_API_URL}/date`);
    
    setDate(`วันที่ ${date.data[0].date} เวลา ${date.data[0].time} น.`);
    setShip(date.data[0].dcost);
    setAdditional(date.data[0].detail);

    const response = order;
    // const responsec = category;
    const cost = 34;
    const dcost = date.data[0].dcost;
    const spend = 14;

    response.data.forEach((elementm) => {
      let price = 0;
      let profit = 0;
      let count = 0;
      let countreal = 0;
      let found = 0
      if (elementm.product !== 's:0:"";') {
        PHP.parse(elementm.product).forEach((element) => {
          // let firstName = element.substr(0, 2);
          // found = responsec.data.find(
          //   (elementc) => elementc.name === firstName
          // );

          // if (!found) {
          //   found = responsec.data.find((elementc) => {
          //     firstName = element.charAt(0);
          //     return elementc.name === firstName;
          //   });
          //   if (!found) {
          //     found = 0
          //   }
          // }

          // price += found.price;
          // profit += found.price - cost;

          found = parseInt(element.match(/\d+/g).join(''));
          price += found;
          profit += found - cost;
          count += 1;
          elementm.status_f === "on" ? (countreal += 1) : (countreal += 0);
        });
      }
      profit += +dcost - spend;

      elementm["total"] = price + dcost;
      elementm.status_f === "on"
        ? (elementm["totalsum"] = price + dcost)
        : (elementm["totalsum"] = 0);
      elementm.status_f === "on"
        ? (elementm["profit"] = profit)
        : (elementm["profit"] = 0);
      elementm["dcost"] = dcost;
      elementm["count"] = count;
      elementm["countreal"] = countreal;
    });
    setOrderList(response.data);
    setLoading(false);
  };

  const copyCilpboard = () => {
    navigator.clipboard.writeText(copyList);
    setShow(false);
    Notify.success("คัดลอกสำเร็จ");
  };

  const getCopyList = (ListId, name, tiktok) => {
    Axios.get(`${process.env.REACT_APP_API_URL}/order/${ListId}`).then(
      (response) => {
        // let copy = [];
        // let found = "";
        let price = 0;

        PHP.parse(response.data["order"]).forEach((element) => {
          console.log(element.match(/\d+/g).join(''));
        //   found = response.data["category"].find((elementc) => {
        //     var firstName = element.substr(0, 2);
        //     return elementc.name === firstName;
        //   });
        //   if (found) {
        //     copy.push([element, found.price]);
        //   } else {
        //     found = response.data["category"].find((elementc) => {
        //       var firstName = element.charAt(0);
        //       return elementc.name === firstName;
        //     });
        //     copy.push([element, found.price]);
        //   }
          price += parseInt(element.match(/\d+/g).join(''));
        });

        // copy.forEach((element) => {
        //   price += element[1];
        // });
        let word = `#สรุปยอด\nคุณ${name}-${tiktok}\n${PHP.parse(
          response.data["order"]
        ).join(", ")}\nราคารวมสินค้า ${price} บาท\nค่าส่งเหมา ${ship} บาท\n\n`;
        price += ship;
        word += `ยอดรวมทั้งสิ้น ${price} บาทครับ`;
        if (additional.length > 0) {
          word += `\n\n${additional}`;
        }

        setCopyList(word);
        setShow(true);
      }
    );
  };

  function handleChange(event) {
    Axios.post(`${process.env.REACT_APP_API_URL}/update`, event).then(() => {});
  }

  useEffect(() => {
    getOrder();
  }, []);

  return (
    <>
      <Report data={orderList} />
      <h1 className="text-slate-200 text-center mt-5 text-2xl">
        รายการคำสั่งซื้อ
      </h1>
      <h2 className="text-center mt-2 text-xl text-slate-400">{date}</h2>
      {loading ? (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden backdrop-blur-sm bg-white/30 flex flex-col items-center justify-center">
          <SpinnerCircularFixed size="100" color="#38bdf8" />
        </div>
      ) : (
        ""
      )}
      {show ? (
        <div
          className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden backdrop-blur-sm bg-white/30 flex flex-col items-center justify-center"
          onClick={() => setShow(false)}
        >
          <div
            className="p-3 text-xl md:text-xl bg-slate-100 rounded mb-3 border-2 border-sky-500 m-2"
            dangerouslySetInnerHTML={{
              __html: copyList.replaceAll("\n", "<br>"),
            }}
          ></div>
          <div
            className="p-3 text-2xl bg-slate-100 rounded cursor-pointer border-2 border-sky-500"
            onClick={copyCilpboard}
          >
            Copy
          </div>
        </div>
      ) : (
        ""
      )}
      {orderList.map((val, key) => {
        return (
          <div
            className={
              `${val.note ? 'bg-yellow-100' : 'bg-slate-100'} text-slate-800 p-2 grid grid-cols-4 gap-2 my-3 rounded-lg ml-6 mx-3 relative mb-8 pb-8 Order-${val.id}`
            }
            key={key}
          >
            <div className="absolute -top-3 left-2">
              <div className="p-1 bg-yellow-600 text-sm text-slate-200 rounded-lg">
                {val.time}
              </div>
            </div>
            <div className="col-span-2 text-sm mt-3">
              <Link to={{ pathname: `edit/${val.id_customer}/${val.id}` }}>
                <div className="flex items-center">
                  <MdEdit /> <span className="pl-1">แก้ไขข้อมูลส่วนตัว</span>
                </div>
              </Link>
              <div className="pl-2 font-bold">รายละเอียด</div>
              <div className="">
                <div className="items-center inline-flex text-2xl rounded-lg border-2 bg-[#ccff00] border-slate-800 -translate-x-7 p-1">
                  <BiUser /> <span className="pl-1">{val.name}</span>
                </div>
                <div className="">
                  <a
                    className="flex items-center"
                    target="_blank"
                    href={"https://www.tiktok.com/@" + val.tiktok_link}
                    rel="noreferrer"
                  >
                    <FaTiktok />
                    <span className="pl-1">{val.tiktok}</span>
                  </a>
                </div>
                <div className="">
                  <a
                    className="flex items-center"
                    target="_blank"
                    href={"https://www.tiktok.com/@" + val.tiktok_link}
                    rel="noreferrer"
                  >
                    <FaTiktok />
                    <span className="pl-1">{val.tiktok_link}</span>
                  </a>
                </div>
                {val.line ? (
                  <div className="flex items-center">
                    <SiLine />
                    <span className="pl-1">{val.line}</span>
                  </div>
                ) : (
                  ""
                )}
                {val.note ? (
                  <div className="flex items-center">
                    หมายเหตุ : 
                    <span className="pl-1 text-red-500">{val.note}</span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-span-2">
              <TagIndex
                handleChange={handleChange}
                data={val.product !== 's:0:"";' ? PHP.parse(val.product) : []}
                id={val.id}
              />
            </div>
            <div className="col-span-4">
              <div className="absolute -bottom-2 left-0 text-sm flex items-center">
                <Button id={val.id} name="สรุปยอด" status={val.status} />
                <Button id={val.id} name="โอนแล้ว" status={val.status_f} />
                <span
                  className="flex items-center p-1 px-2 rounded-lg ml-1 bg-slate-200 inline-flex border-1 border-slate-800 shadow-lg hover:scale-105 hover:shadow-sm transition cursor-pointer"
                  onClick={() => getCopyList(val.id, val.name, val.tiktok)}
                >
                  <MdContentCopy />{" "}
                  <span className="pl-1">ยอดโอน {val.total} บาท</span>
                </span>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Home;
