import { useState } from "react";
import Serialize from "./serialize.js"
import { Confirm } from 'notiflix/build/notiflix-confirm-aio';

Confirm.init({
  fontFamily: 'Mitr',
});

const Tagindex = ({ handleChange, data, id }) => {
  const [tags, setTags] = useState(data);
  const addTag = (e) => {
    if (e.key === " " ||
      e.code === "Space" ||
      e.code === "Enter" ||
      e.keyCode === 32) {
      if (e.target.value.trim().length > 0) {
        handleChange({
          id: id,
          product: Serialize([...tags, e.target.value.toUpperCase().trim()])
        });
        setTags([...tags, e.target.value.toUpperCase().trim()]);
        e.target.value = "";
      }
    }
  };
  const removeTag = (removedTag) => {
    Confirm.show(
      'ลบข้อมูลสินค้า',
      'คุณต้องการลบใช่ไหม',
      'ตกลง',
      'ยกเลิก',
      () => {
        const newTags = tags.filter((tag) => tag !== removedTag);
        setTags(newTags);
        handleChange({
          id: id,
          product: Serialize(newTags)
        });
      },
      );
    
  };
  return (
    <>
      <div className="grid grid-cols-3 md:grid-cols-3 gap-1 text-center align-items-center content-start">
        {tags.map((tag, index) => {
          return (
            <div
              key={index}
              className="border border-gray-300 rounded-lg p-1 bg-body text-slate-200 shadow-sm mb-2 text-md"
            >
              {tag} <span className="cursor-pointer text-xl" onClick={() => removeTag(tag)}>x</span>
            </div>
          );
        })}
      </div>
      <input
        className="w-full mt-1 rounded-xl border-gray-300 shadow-sm py-2 pl-3 uppercase"
        onKeyDown={addTag}
      />

    </>
  );
};
export default Tagindex;
